import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {IDevice, InvoiceGroup, IVehicle} from "../../../../lib/types/types";
import {ClientFirmService} from "../../../../services/clientFirmService";
import {Card} from "primereact/card";
import {ReactiveTable} from "react-ive-tables";
import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";
import {useDialog} from "../../../../lib/hooks/useDialog";
import AssignVehiclesToGroup from "./AssignVehiclesToGroup";
import {TariffMultipleAssign} from "../../../tariffs/TariffMultipleAssign";
import {LoadInvoiceDate} from "../../../vehicles/LoadInvoiceDate";
import {UtilService} from "../../../../services/utilService";
import {Dropdown} from "primereact/dropdown";
import {IDropdownOption} from "@xal3xfx/react-utils/dist/util-service";
import styled from "styled-components";

interface Props {
    clientFirmId: number;
    invoiceGroups?: InvoiceGroup[];
    refreshTable?: number;
    selectVehicle: (_: IVehicle | undefined) => void
}

const TarifCountWrapper = styled.div`
    background-color: #46b04a;
    border-radius: 5rem;
    color: white;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
`

const FirmVehicles: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const clientFirmService = new ClientFirmService();
    const {showDialog, hideDialog} = useDialog();

    const devicesOp = useRef<any>();
    const gpsAccountsOp = useRef<any>();

    const [clientFirmVehicles, setClientFirmVehicles] = useState<IVehicle[]>();
    const [vehicleIdsWithoutInvoiceGroups, setVehicleIdsWithoutInvoiceGroups] = useState<number[]>()
    const [selectedVehicles, setSelectedVehicles] = useState<IVehicle[]>()
    const [selectedVehicleForDetails, setSelectedVehicleForDetails] = useState<IVehicle>();
    const [refreshTable, setRefreshTable] = useState<number>();


    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if(props.refreshTable) {
            setClientFirmVehicles(undefined);
            setTimeout(() => loadData(), 10);
        }
    }, [props.refreshTable])

    const loadData = () => {
        loadClientFirmVehicles();
        // loadClientFirmVehiclesWithoutVehicleIds();
        setRefreshTable(Date.now())
    }

    const loadClientFirmVehicles = () => {
        //TODO: Backend should return the invoiceGroups correctly and sort the records -> the ones which have not invoice group
        // or tariffs should be the first ones
        clientFirmService.getClientFirmVehicles(props.clientFirmId)
            .then(setClientFirmVehicles)
            .catch(console.error)
    }

    const loadClientFirmVehiclesWithoutVehicleIds = () => {
        clientFirmService.getAllVehiclesForGroupWithoutInvoiceGroup(props.clientFirmId)
            .then(resp => {
                setVehicleIdsWithoutInvoiceGroups(resp.map(el => el.vehicleId))
            })
            .catch(console.error)
    }

    const generateDeviceDtosTemplate = (rowData: IVehicle) => {
        if (rowData.deviceDtos && rowData.deviceDtos.length > 0 && rowData.deviceDtos[0] !== undefined) {
            let buttonLabel = rowData.deviceDtos[0].deviceNumber?.toString();
            if (rowData.deviceDtos.length > 1) buttonLabel += " " + f({id: "andMore"}, {count: rowData.deviceDtos.length - 1});
            return <Button label={buttonLabel} className={"w-full"} onClick={(e) => {
                setSelectedVehicleForDetails(rowData);
                devicesOp.current?.toggle(e)
            }}/>
        } else {
            return <label>{f({id: "noDevices"})}</label>
        }
    }

    const getTarifsCountTemplate = (count: number) => {
        return <div className={"w-full flex justify-content-center"}>
            <TarifCountWrapper>{count}</TarifCountWrapper>
        </div>
    }

    const getColumnTemplate = () => {
        const checkIcon = <i style={{color: '#46b04a'}}
                             className={'fas fa-check-circle fa-2x'}/>;

        const timesIcon = <i style={{color: '#fc0303'}}
                             className={'fas fa-times-circle fa-2x'}/>;

        return {
            deviceDtos: generateDeviceDtosTemplate,
            invoiceGroupId: (rowData: IVehicle) => rowData.invoiceGroupId === 0 ? timesIcon : checkIcon,
            tarifsCount: (rowData: IVehicle) => rowData.tarifsCount > 0 ? getTarifsCountTemplate(rowData.tarifsCount) : timesIcon,
            gpsModel: (rowData: IVehicle) => rowData.deviceDtos.find(el => el.deviceTypeId === 2)?.deviceModelName,
            // @ts-ignore
            vehicleStatus: (rowData: IVehicle) => UtilService.getVehicleStatuses(f)[rowData['vehicleStatus']],
            gpsAccounts: generateGpsAccountsTemplate,
        }
    }

    const generateGpsAccountsTemplate = (rowData: IVehicle) => {
        if (rowData.gpsAccounts && rowData.gpsAccounts.length > 0) {
            let buttonLabel = rowData.gpsAccounts[0].item2;
            if (rowData.gpsAccounts.length > 1) buttonLabel += " " + f({id: "andMore"}, {count: rowData.gpsAccounts.length - 1});
            return <Button className={"w-full"} label={buttonLabel} onClick={(e) => {
                setSelectedVehicleForDetails(rowData);
                gpsAccountsOp.current?.toggle(e)
            }}/>
        } else {
            return <label>{f({id: "noAccounts"})}</label>
        }
    }

    const getStatusOptions = (): IDropdownOption[] => {
        return Object.values(UtilService.getVehicleStatuses(f)).map((el, index) => {
            return {key: index, id: index, description: el}
        })
    }

    const getSpecialFilters = () => {
        return {
            vehicleStatus: (options: any) => <Dropdown showClear value={options.value}
                                                       optionValue={"id"} optionLabel={"description"}
                                                       options={getStatusOptions()}
                                                       placeholder={f({id: 'choose'})}
                                                       onChange={(e) => options.filterApplyCallback(e.value)}
                                                       style={{textAlign: "left"}}/>,
            invoiceGroupId: (options: any) => <Dropdown showClear value={options.value}
                                                       optionValue={"id"} optionLabel={"description"}
                                                       options={UtilService.yesNoOptions}
                                                       placeholder={f({id: 'choose'})}
                                                       onChange={(e) => options.filterApplyCallback(e.value)}
                                                       style={{textAlign: "left"}}/>,
            // tarifsCount: (options: any) => <Dropdown showClear value={options.value}
            //                                             optionValue={"id"} optionLabel={"description"}
            //                                             options={UtilService.yesNoOptions}
            //                                             placeholder={f({id: 'choose'})}
            //                                             onChange={(e) => options.filterApplyCallback(e.value)}
            //                                             style={{textAlign: "left"}}/>,

        }
    }

    // useEffect(() => {
    //     console.log("SELECTED VEHICLES: ", selectedVehicles)
    // }, [selectedVehicles]);

    const showInvoiceGroupsDialog = () => {
        if (!selectedVehicles) return;
        showDialog({
            newContent: <AssignVehiclesToGroup clientFirmId={props.clientFirmId} selectedVehicles={selectedVehicles}
                                               onSave={onSuccessfulOperation}/>,
            heading: f({id: 'addToInvoiceGroup'}),
            style: {maxWidth: "70vw"}
        });
    }

    const showTariffsDialog = () => {
        if(!selectedVehicles) return;
        clientFirmService.getAllTariffsForFirm(props.clientFirmId).then(resp => {
            showDialog({
                newContent: <TariffMultipleAssign vehiclesData={selectedVehicles}
                                                  clientFirmTariffsData={resp}
                                                  hideMultipleAssign={() => 0}
                                                  callback={onSuccessfulOperation}/>,
                heading: <h3>Multiple Assign</h3>
            })
        });

    }

    const showInvoiceDateDialog = () => {
        if(!selectedVehicles) return;
        showDialog({
            newContent: <LoadInvoiceDate createdCb={onSuccessfulOperation}
                                         currentInvoiceDate={new Date()}
                                         vehicleId={selectedVehicles.map(el => el.vehicleId)}/>,
            heading: f({id: "loadInvoiceDateForVehiclesNumber"}, {howMany: selectedVehicles.length})
        })

    }

    const onSuccessfulOperation = () => {
        hideDialog();
        setClientFirmVehicles(undefined);
        setTimeout(() => loadData(), 10);
    }

    const handleSelection = (e:any) => {
        console.log(e);
        setSelectedVehicles(e.filter((el: any) => el !== undefined));
        if(Array.isArray(e)) {
            if(e.length > 0)
                props.selectVehicle(e[e.length - 1]);
            else props.selectVehicle(undefined)
        }
    }

    const handleExternalFilter = {
        invoiceGroupId: (rowData: IVehicle, filterValue: any) => {
            if(!filterValue) {
                return rowData.invoiceGroupId === 0;
            }else {
                return rowData.invoiceGroupId !== 0;
            }
        },
        // tarifsCount: (rowData: IVehicle, filterValue: any) => {
        //     if(!filterValue) {
        //         return rowData.tarifsCount === 0;
        //     }else {
        //         return rowData.tarifsCount !== 0;
        //     }
        // },
        gpsModel: (rowData: IVehicle, filterValue: any) => {
            const currentGps = rowData.deviceDtos.find(el => el.deviceTypeId === 2);
            if(!currentGps) return false;
            return currentGps.deviceModelName.includes(filterValue);
        }
    }

    return <>
        <OverlayPanel ref={devicesOp} showCloseIcon pt={{content: {style: {width: '30vw'}}}}>
            <ReactiveTable data={selectedVehicleForDetails?.deviceDtos || []}
                           columnOrder={["deviceNumber", 'simCardNumber', 'deviceModelName', 'deviceTypeName']}
                           showFilters={false}/>
        </OverlayPanel>

        <OverlayPanel ref={gpsAccountsOp} showCloseIcon>
            <ReactiveTable data={selectedVehicleForDetails?.gpsAccounts.map(el => {
                return {alias: el.item1, account: el.item2}
            }) || []}
                           columnOrder={["alias", "account", "operations"]}
                           showFilters={false}/>
        </OverlayPanel>

        <div className={"col-12 md:pr-3 flex"}>
            <Card className={"border-round-3xl flex flex-column col-12 shadow-6"}>
                <div className={"col-12 flex flex-row align-items-center flex-wrap"}>
                    <h2>{f({id: "vehicles"})}</h2>
                    <Button className={"ml-auto mr-2"} label={f({id: "invoiceGroups"})}
                            disabled={!(selectedVehicles && selectedVehicles.length > 0)}
                            onClick={showInvoiceGroupsDialog}/>
                    <Button className={"mr-2"} label={f({id: "tariffs"})} disabled={!(selectedVehicles && selectedVehicles.length > 0)} onClick={showTariffsDialog}/>
                    <Button label={f({id: "invoiceStartDate"})} disabled={!(selectedVehicles && selectedVehicles.length > 0)} onClick={showInvoiceDateDialog}/>
                </div>
                {clientFirmVehicles &&
                    <ReactiveTable
                        data={clientFirmVehicles}
                        columnOrder={["vehicleLpn", "vehicleTable", 'deviceDtos', 'gpsModel', 'gpsAccounts', 'vehicleStatus', 'invoiceGroupId', 'tarifsCount']}
                        columnTemplate={getColumnTemplate()}
                        setSelected={handleSelection}
                        columnStyle={{deviceDtos: {body: {width: "200px"}, header: {}}}}
                        selectionKey={'vehicleId'}
                        specialFilters={getSpecialFilters()}
                        selectionMode={"checkbox"}
                        externalFilters={handleExternalFilter}
                    />
                }
            </Card>
        </div>
    </>
};

export default FirmVehicles

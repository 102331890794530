import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Editor} from "primereact/editor";
import {Button} from "primereact/button";
import {Api, InternalTaskReplyDto} from "../../services/backend-api";
import moment from "moment";

interface Props {
    comments: InternalTaskReplyDto[];
    internalTaskId: number;
    createCommentCb: () => void
}

const InternalTaskComments: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const api = new Api();

    const [comment, setComment] = useState<any>()
    const [showAddComment, setShowAddComment] = useState(false)
    const didMountRef = useRef(false);

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    const generateComments = () => {
        return props.comments.map(comment => {
            const header = <div className={"col-12 flex"}>
                <div className={"font-bold flex align-items-center"} style={{width: "auto"}}>{comment.replyByEmployeeName} - </div>
                <div className={"col-3"}>{moment.utc(comment.replyDateTime).local().format("DD-MM-yyyy HH:mm:ss")}</div>
            </div>
            return <div key={comment.internalTaskReplyId}>
                    <Editor value={comment.reply!} headerTemplate={header}
                            readOnly={true}
                    />
                </div>
        })
    }

    const handleCreateComment = () => {
        api.internaltasks.repliesCreate({internalTaskId: props.internalTaskId, reply: comment})
            .then(resp => {
                if(resp) {
                    setShowAddComment(false);
                    setComment("");
                    props.createCommentCb();
                }
            })
            .catch()
    }

    return <>
        <h2>{f({id: "comments"})}</h2>
        {showAddComment ?
            <>
                <Editor value={comment} onTextChange={(e) => setComment(e.htmlValue)}
                        style={{height: '200px'}}
                        placeholder={f({id: "addComment"})}/>
                <div className={"col-12 flex justify-content-start"}>
                    <Button className={"md:col-2 col-6"} label={f({id: "create"})} onClick={handleCreateComment}/>
                    <Button className={"md:col-2 col-6"} text label={f({id: "cancel"})} onClick={() => setShowAddComment(false)}/>
                </div>
            </>
            :
            <div className={"md:col-2 col-6"}>
                <Button className={"mb-3"} label={f({id: "addComment"})} onClick={() => setShowAddComment(true)}/>
            </div>
        }

        {generateComments()}

    </>
};

export default InternalTaskComments
